<template>
  <div class="main-panel">
    <div class="title">
      <span>待审核泛团</span>
      <div class="d-inline-block ml-5 search">
        <el-input type="text" clearable v-model="search" placeholder="请输入ID或泛团名" style="width: 350px;"></el-input>
        <el-button type="primary" @click="searchTeam" style="margin-left: -3px;">搜索</el-button>
      </div>
    </div>
    <el-table :data="list"  style="width: 100%" :header-cell-style="{'text-align':'center','color': '#333333'}" :cell-style="{'text-align':'center','color': '#555555'}"  empty-text>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <div class="expand-main">
              <span>泛团简介：</span>
              <span>{{props.row.profile}}</span>
            </div>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column  label="ID"  prop="teamid"></el-table-column>
      <el-table-column  label="泛团名称" prop="teamname"></el-table-column>
      <el-table-column  label="主要业务" width="180px">
        <template slot-scope="scope">
          <span class="tag" v-for="it in businessArr (scope.row.business)" :key="it">{{it}}</span>
        </template>
      </el-table-column>
      <el-table-column  label="联系方式" prop="phone"></el-table-column>
      <el-table-column  label="联系邮箱" prop="email"></el-table-column>
      <el-table-column  label="泛团性质" prop="teamtype">
        <template slot-scope="scope">
          <span>{{scope.row.teamtype === 0? '企业': (scope.row.teamtype === 1? '个人' : '-')}}</span>
        </template>
      </el-table-column>
      <el-table-column  label="历史状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status === -3">整改</span>
          <span v-if="scope.row.status === -2">拒绝</span>
          <span v-if="scope.row.status === -1">-</span>
        </template>
      </el-table-column>
      <el-table-column  label="操作" min-width="120px">
        <template slot-scope="scope">
          <div class="btns">
            <span class="pass btn" @click="pass(scope.row.teamid, scope.row.status)">通过</span>
            <span class="refused btn" @click="refused(scope.row.teamid, scope.row.status)">拒绝</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-if="list.length > 0" layout="prev, pager, next" :page-size="10"  :total="totalRow" @current-change="changePage">
    </el-pagination>
    <el-dialog title="拒绝理由" top="25vh" :show-close="false" :visible.sync="dialogVisible" :before-close="handleClose">
      <div class="select-main">
        <el-select size="small" v-model="reason" placeholder="请选择">
        <el-option label="因包含不当信息因此做下线处理"  value="因包含不当信息因此做下线处理"></el-option>
        <el-option label="因受到用户举报因此做下线处理"  value="因受到用户举报因此做下线处理"></el-option>
      </el-select>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" class="cancel" @click="cancel">取消</el-button>
        <el-button size="small" type="primary" class="sure" @click="sure" :disabled="!reason">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getTeamsInfo, sendChstatus } from '@/api/team-info'
export default {
  name: 'to-audit',
  data () {
    return {
      search: '',
      name: 'to-audit',
      page: 1,
      totalRow: 0,
      list: [],
      option: [
        {
          label: '图片',
          value: 1
        },
        {
          label: '文本',
          value: 2
        },
        {
          label: '视频',
          value: 4
        },
        {
          label: '语音',
          value: 8
        },
        {
          label: '采集',
          value: 16
        },
        {
          label: '3D点云标注',
          value: 32
        }
      ],
      reason: '',
      id: '',
      status: 0,
      dialogVisible: false
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      const params = {
        search: this.search,
        page: this.page,
        pageSize: 10,
        status: -2
      }
      const res = await getTeamsInfo(params)
      if (res.code === 0) {
        this.totalRow = res.data.pager.totalRow
        this.list = res.data.teams || []
      } else {
        this.$notify({
          title: '失败',
          type: 'error',
          message: res.message,
          duration: 2000
        })
      }
    },
    searchTeam () {
      this.page = 1
      this.getList()
    },
    changePage (p) {
      this.page = p
      this.getList()
    },
    businessArr (business) {
      const arr = []
      this.option.map(
        val => {
          if (val.value & business) {
            arr.push(val.label)
          }
        }
      )
      return arr
    },
    formatTime (currentTime) {
      const time = new Date(currentTime * 1000)
      const y = time.getFullYear()
      const m = (time.getMonth() + 1) < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1
      const d = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
      const dateTime = y + '.' + m + '.' + d
      return dateTime
    },
    pass (id, status) {
      const data = {
        teamid: id,
        status: status === -3 ? 0 : 99
      }
      sendChstatus(data).then(res => {
        if (res.code === 0) {
          if (this.list.length === 1) {
            this.page = this.page - 1
          }
          this.getList()
          this.$notify({
            title: '成功',
            message: res.message,
            type: 'success',
            duration: 1000
          })
        } else {
          this.$notify({
            title: '失败',
            type: 'error',
            message: res.message,
            duration: 1000
          })
        }
      })
    },
    refused (id, status) {
      this.dialogVisible = true
      this.id = id
      if (status === -3) {
        this.status = 3
      } else {
        this.status = 2
      }
    },
    cancel () {
      this.reason = ''
      this.dialogVisible = false
    },
    sure () {
      const data = {
        teamid: this.id,
        status: this.status,
        comment: this.reason
      }
      sendChstatus(data).then(res => {
        if (res.code === 0) {
          this.reason = ''
          this.dialogVisible = false
          if (this.list.length === 1) {
            this.page = this.page - 1
          }
          this.getList()
          this.$notify({
            title: '成功',
            message: res.message,
            type: 'success',
            duration: 1000
          })
        } else {
          this.$notify({
            title: '失败',
            type: 'error',
            message: res.message,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main-panel{
  .title{
    font-size: 20px;
    line-height: 20px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 36px;
  }
  .el-table{
    .expand-main{
      display: flex;
      span:first-child{
        width: 70px;
      }
      span:last-child{
        flex: 1;
      }
    }
    .btns{
      display: flex;
      justify-content: center;
      span{
        margin: 0 4px;
        min-width: 55px;
        max-width: 55px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        border-radius: 8px;
      }
      .pass{
        background: #3775F6;
      }
      .refused{
        background: #F56C6C;
      }
    }
    .tag{
      display: inline-block;
      line-height: 19px;
      padding: 3px 5px;
      color: #666666;
      border: 1px solid #666666;
      font-size: 13px;
      margin-right: 7px;
      margin-bottom: 4px;
    }
    .btn{
      padding: 0 4px;
      font-size: 14px;
    }
  }
  .el-pagination{
    margin-top: 190px;
  }
  .expand-main{
    overflow: hidden;
    padding: 0 1px;
    .last-row{
      padding-right: 50px;
    }
    .row{
      display: flex;
      margin: 0;
      margin-bottom: 10px;
      /deep/.el-input{
        flex: 1;
        .el-input__inner{
          border-radius: 0px
        }
        .el-input__inner:focus{
          border: 1px solid #707070;
        }
      }
      span{
        flex: 1;
      }
    }
    .warp{
      width: 100%;
      display: flex;
      span:first-child{
        flex: 1;
        max-width: 70px;
        min-width: 70px;
      }
      span:st-child{
        flex: 9;
        width: 100%;
      }
    }
  }
}
</style>
<style lang="less">
.el-dialog{
  width: 340px;
  height: 286px;
  border-radius: 15px;
  padding: 0 15px;
  .el-dialog__header{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  .el-dialog__body{
    padding-top: 15px;
  }
  .select-main{
    height: 100px;
    .el-select{
      width: 100%;
    }
  }
  .dialog-footer{
    display: flex;
    justify-content: center;
    .el-button{
      width: 76px;
      border: 2px;
      margin: 0 18px;
      outline: 0;
    }
    .cancel{
      color: #3775F6;
      border: 1px solid #3775F6;
      &:hover{
        background-color: #fff;
      }
    }
    .sure{
      background-color: #3775F6;
      border: 1px solid #3775F6;
      &:disabled{
        opacity: 0.7;
      }
    }
  }
}
</style>
